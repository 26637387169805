import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import queryString from 'query-string'
import Layout from '../components/layout'
import Seo from '../components/seo'
import CutOverview from '../components/sections/parts/cut-overview'
import Bubble from '../components/svg-icons/bubble'
import RecipeCarouselBlocks from '../components/sections/recipe-carousel-blocks'
import NutritionTicker from '../components/sections/nutrition-ticker'
import ButtonLink from '../components/common/button-link'
import NutritionPanel from '../components/common/nutrition-panel'
import NutritionIngredientsList from '../components/common/nutrition-ingredients-list'

const CutsPage = ({ location }) => {
  const cuts = useStaticQuery(graphql`
    query AllCuts {
      allWpCut {
        nodes {
          content
          title
          slug
          nutritionFacts {
            overview {
              calories
              fieldGroupName
              ingredientsList
              servingSize
              servingsPerContainer
            }
            panelBottom {
              amount
              fieldGroupName
              item
              percentDailyValue
            }
            panelTop {
              amount
              fieldGroupName
              item
              percentDailyValue
            }
          }
          featuredImage {
            node {
              slug
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 636, height: 636, placeholder: BLURRED, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  `)

  gsap.registerPlugin(ScrollToPlugin)

  // Check URL to see if a cut is selected
  // If so, set that cut as the current tab
  const { nfp } = queryString.parse(location.search)
  const [currentTab, setCurrentTab] = useState(null)
  useEffect(() => {
    setCurrentTab(nfp || cuts.allWpCut.nodes[0].slug)
    nfp && gsap.to(window, { duration: 0.35, scrollTo: { y: '#cutsNutrition', offsetY: 50 } })
    console.log('scrolling to nfp')
  }, [])
  // When a tab is selected, update the current tab and the URL
  const handleSelect = k => {
    setCurrentTab(k)
    if (typeof window !== 'undefined') {
      window.history.pushState({}, '', `${location.pathname}?nfp=${k}`)
    }
  }

  return (
    <Layout>
      <Seo title="Our Cuts" />
      <header id="cutsHero" className="px-1 px-md-0">
        <div className="container py-2 py-md-3">
          <Row>
            <Col lg={{ order: 'last', span: 6 }} className="d-flex align-items-center">
              <div className="px-xl-2">
                <StaticImage
                  src="../images/hero/cuts-hero.jpg"
                  width={636}
                  quality={95}
                  formats={['AUTO', 'WEBP']}
                  alt="Meati™ Cuts"
                  placeholder="blurred"
                  layout="constrained"
                  className="mb-1 mb-lg-0 me-lg-n3"
                />
              </div>
            </Col>
            <Col lg="6" className="d-flex flex-column justify-content-center text-primary">
              <h1 className="fw-bold">Our Cuts</h1>
              <p className="subtitle">
                Deliciously pre-seasoned or a totally blank canvas for you to work your secret-recipe magic — our cuts
                are perfect for sautéing, grilling, and more!
              </p>
              <div className="cut-overview d-flex align-items-center">
                <ButtonLink btnText="Shop" btnLink="/shop" btnClass="btn btn-warning" />
                <AnchorLink
                  to="#cutsNutrition"
                  title="See Nutrition Info"
                  className="btn btn-link rounded-0 text-dark px-0 ms-1"
                  data-ad-element-type="cta button"
                  data-ad-text="See Nutrition Info"
                  data-ad-button-id="version 1"
                  data-ad-button-location="body"
                />
              </div>
            </Col>
          </Row>
        </div>
      </header>
      <NutritionTicker />
      <section id="cutsOverview" className="py-1">
        <Container>
          {cuts.allWpCut.nodes.map((node, index) => (
            <CutOverview key={index} node={node} />
          ))}
        </Container>
      </section>
      <section id="cutsNutrition" className="py-1 py-md-2 py-lg-3 py-xl-4">
        <Container id="nfp">
          <h2 className="text-center">Nutrition</h2>
          <Tab.Container id="cutsNutritionTabs" activeKey={currentTab} onSelect={handleSelect} mountOnEnter>
            <nav id="cutsNutritionNav" className="py-12 py-lg-1 bg-white secondary-nav" data-active={currentTab}>
              <Nav className="list-unstyled mb-0 d-flex justify-content-center flex-wrap">
                {cuts.allWpCut.nodes.map((node, index) => (
                  <Nav.Item key={`nutrition-tab-${index}`}>
                    <Nav.Link eventKey={node.slug} className="nutrition-bubble" as="div" role="button">
                      <Bubble text={node.title} type={node.slug} />
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </nav>
            <Tab.Content>
              {cuts.allWpCut.nodes.map((node, index) => (
                <Tab.Pane key={`nutrition-pane-${index}`} eventKey={node.slug}>
                  <Row>
                    <Col lg="8" className={`nfp nfp-${node.slug}`}>
                      <NutritionPanel node={node} />
                    </Col>
                    <Col lg="4">
                      <div className='p-1'>
                        <NutritionIngredientsList showTitle={true} ingredients={node.nutritionFacts.overview.ingredientsList} />
                        <h6>
                          <AnchorLink
                            to={`/kitchen#${node.slug}`}
                            title="How to Prepare"
                            className="d-inline-block px-14 pb-14 border-bottom border-primary"
                            data-ad-element-type="cta button"
                            data-ad-text="How to prepare"
                            data-ad-button-id="version 1"
                            data-ad-button-location="body"
                          >
                            How to prepare
                          </AnchorLink>
                        </h6>
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </Container>
      </section>
      <RecipeCarouselBlocks handle="all" sectionTitle="Our Chef's Faves" />
    </Layout>
  )
}

export default CutsPage
