import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { useMediaQuery } from 'usehooks-ts'

const NutritionTicker = (props) => {
  let styles
  let xPercent
  let duration

  const small = useMediaQuery('(max-width: 767px)')
  const med = useMediaQuery('(min-width: 768px) and (max-width: 1199px)')

  if (small) {
    styles = {
      container: {
        width: '500vw',
      },
      items: {
        width: '250vw',
      },
    }
    duration = 15
  } else if (med) {
    styles = {
      container: {
        width: '280vw',
      },
      items: {
        width: '140vw',
      },
    }
    duration = 19
  } else {
    styles = {
      container: {
        width: '200vw',
      },
      items: {
        width: '100vw',
      },
    }
    duration = 15
  }

  useEffect(() => {
    gsap.to('.text-ticker-items', {
      duration,
      xPercent: -100,
      ease: 'none',
      repeat: -1,
    })
  }, [])

  const items = [
    '95% mushroom root',
    'Complete Protein',
    'Whole Food',
    'Excellent Source of Fiber',
    'No Soy, Gluten, or Wheat',
  ]

  const tickerSegment = (
    <div className="text-ticker-items d-flex align-items-center justify-content-between" style={styles.items}>
      {items.map((item, index) => <div className="px-1 flex-grow-1" key={index}>{item}</div>)}
    </div>
  )

  return (
    <section id="nutritionTicker" className="text-ticker py-1 bg-light">
      <div className="text-ticker-container d-flex text-uppercase fw-bold lh-1 text-center" style={styles.container}>
        {tickerSegment}
        {tickerSegment}
      </div>
    </section>
  )
}

export default NutritionTicker
